<template>
    <component :is="setContactComponent" />
</template>

<script>

export default {
    components: {
        DefaultContactInfoComponent: () => import('./ContactInfoComponents/DefaultContactInfoComponent.vue'),
        AffaldPlusContactInfoComponent: () => import('./ContactInfoComponents/AffaldPlusContactInfoComponent.vue'),
        KredslobContactInfoComponent: () => import('./ContactInfoComponents/KredslobContactInfoComponent.vue')
    },
    data() {
        return {
        }
    },
    computed: {
        setContactComponent() {
            switch (process.env.VUE_APP_TERMS_COMPANY_NAME) {
                case 'Affald Plus':
                    return 'AffaldPlusContactInfoComponent'
                case 'Kredsløb Genbrug':
                    return 'KredslobContactInfoComponent'
                case 'Optidata':
                    return 'KredslobContactInfoComponent'
                default:
                    return 'DefaultContactInfoComponent'
            }
        },
    },
    created() {        
    },    
    methods: {
    }
}
</script>
